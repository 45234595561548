<template>
  <b-sidebar
    id="sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    no-close-on-backdrop
    right
    @shown="loadData"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0 text-capitalize">
          {{ `${isAdd ? $t('actions.add') : $t('actions.edit')} ${ $tc('alarm_level.title', 1)}`}}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- description -->
          <validation-provider
            #default="validationContext"
            name="description"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('alarm_level.description')"
              label-for="email"
            >
              <b-form-input
                id="description"
                v-model="itemData.description"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- level -->
          <validation-provider
            #default="validationContext"
            name="level"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('alarm_level.level')"
              label-for="level"
            >
              <b-form-input
                id="level"
                v-model="itemData.level"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <!-- color -->
          <validation-provider
            #default="validationContext"
            name="color"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('alarm_level.color')"
              label-for="color"
            >
              <b-form-input
                id="color"
                v-model="itemData.color"
                :state="getValidationState(validationContext)"
                trim
                type="color"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Tenant -->
          <validation-provider
            #default="validationContext"
            name="tenant"
          >
            <b-form-group
              :label="$tc('tenants.title', 1)"
              label-for="tenant"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_tenant"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="tenantOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="tenant"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Type alarms -->
          <validation-provider
            #default="validationContext"
            name="alarmTypes"
          >
            <b-form-group
              :label="$tc('types_alarms.title', 2)"
              label-for="alarmTypes"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_alarm_types"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="typeAlarmOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="tenant"
                multiple
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <vue-dropzone
            id="dropzone"
            ref="dropzoneSound"
            :options="dropzoneOptions"
            class="dropzone-main"
            @vdropzone-file-added="addFile"
            @vdropzone-removed-file="removeFile"
            :useCustomSlot=true
          >
            <div class="dropzone-custom-content">
              <h3 class="dropzone-custom-title">{{ $t('actions.drag_drop_1') }}</h3>
              <div class="subtitle">{{ $t('actions.drag_drop_2') }}</div>
              <div class="subtitle">{{ $t('alarm_level.allowed') }}</div>
            </div>
          </vue-dropzone>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 text-capitalize"
              type="submit"
            >
              {{ $t('actions.save') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="text-capitalize"
            >
              {{ $t('actions.cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent
  from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import vue2Dropzone from "vue2-dropzone"
import "vue2-dropzone/dist/vue2Dropzone.min.css"
import { useUtils as useI18nUtils } from "@core/libs/i18n"

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,

    vueDropzone: vue2Dropzone,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: false,
    },
    tenantOptions: {
      type: Array,
      required: true,
    },
    typeAlarmOptions: {
      type: Array,
      required: true,
    },
    isAdd: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const blankData = {
      description: '',
      level: '',
      color: '',
      id_tenant: '',
      id_alarm_types: [],
      sound: null
    }

    const { t } = useI18nUtils()

    const dropzoneOptions = ref({
      url: "https://httpbin.org/post",
      paramName: "image",
      autoProcessQueue: false,
      addRemoveLinks: true,
      dictRemoveFile: t("actions.delete"),
      acceptedFiles: ".mp3",
    })

    const dropzoneSound = ref(null)

    const addFile = (file) => {
      dropzoneSound.value = file
    }

    const removeFile = () => {
      dropzoneSound.value = null
    }

    const toast = useToast()

    const itemData = ref(JSON.parse(JSON.stringify(blankData)))

    const resetData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankData))
      dropzoneSound.value.removeAllFiles()
    }

    const onSubmit = () => {
      let req = itemData.value

      const data = new FormData()
      data.append("description", itemData.value.description)
      data.append("level", itemData.value.level)
      data.append("color", itemData.value.color)

      if (itemData.value.id_tenant)
        data.append("id_tenant", itemData.value.id_tenant)

      for (var i = 0; i < itemData.value.id_alarm_types.length; i++) {
        data.append('alarmTypes[]', itemData.value.id_alarm_types[i])
      }

      if (dropzoneSound.value.getAcceptedFiles().length > 0) {
        data.append("sound", dropzoneSound.value.getAcceptedFiles()[0]);
      }

      let dispatch

      if (props.isAdd) {
        dispatch = store.dispatch('alarm_level/add', data)
      } else {
        const req = {
          id: itemData.value.id,
          params: data,
        }

        dispatch = store.dispatch('alarm_level/edit', req)
      }

      dispatch
        .then((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          emit('refetch-data')
          emit('update:is-sidebar-active', false)
        })
        .catch((response) => {
          if (response.response.status == 422) {
            let string = ``
            Object.entries(response.response.data.data).forEach(entry => {
              const [key, value] = entry
              string += `${key} - ${value}<br>`
            })
            toast({
              component: ToastificationContent,
              props: {
                title: `${response.response.data.message}`,
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: string
              },
            },{
              timeout: 10000
            }
            )
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm
    } = formValidation(resetData)

    const loadData = () => {
      if (!props.isAdd) {
        itemData.value = props.itemEdit

        itemData.value.id_alarm_types = props.itemEdit.alarmTypes ? props.itemEdit.alarmTypes.map(alarmType => (alarmType.id)) : null

        if(props.itemEdit.media) {
          let file = { size: props.itemEdit.media.size, name: props.itemEdit.media.name, type: props.itemEdit.media.mime_type };
          dropzoneSound.value.manuallyAddFile(file, props.itemEdit.media.url);
        }
      }
    }

    return {
      itemData,
      onSubmit,
      refFormObserver,
      getValidationState,
      loadData,
      resetForm,

      dropzoneOptions,
      dropzoneSound,
      addFile,
      removeFile,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.dropzone-custom-title {
  margin-top: 0;
  color: #00b782;
}

.subtitle {
  color: #314b5f;
}
</style>
